<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export interface Props {
  centerImages: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  centerImages: false,
});

</script>

<template>
  <div class="flex flex-col justify-items-center gap-y-2">
    <div class="flex items-center w-fit" :class="{'self-center': props.centerImages}">
      <img src="/Karin.png" alt="Karin" class="-mr-4 size-12" />
      <img src="/Daan.png" alt="Daan" class="-mr-4 z-50 size-14" />
      <img src="/Ivonne.png" alt="Ivonne" class="shrink-0 size-12" />
    </div>
    <div class="flex flex-col gap-y-1">
      <span class="font-semibold">Klantsupport</span>
      <span>
        <FontAwesomeIcon :icon="['fas', 'envelope']" size="1x" class="text-green-700 mr-2" />
        info@mogelijk.nl
      </span>
      <span>
        <FontAwesomeIcon :icon="['fas', 'phone']" size="1x" class="text-green-700 mr-2" />
        +31 (0)346 - 250 171
      </span>
    </div>
  </div>
</template>
